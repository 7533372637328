import React from 'react'
import GetCMSPageQuery from "../../graphql/requests/GetCMSPage"
import {GetCMSPage, GetCMSPageVariables} from "../../graphql/requests/types/GetCMSPage"
import {Loader} from "../loader"
import {useQuery} from "@apollo/react-hooks"
import {useParams} from "react-router-dom"
import {HideLine, StandardTitle} from "../standard-title"
import {FullHeightContainer} from "../full-height-container"

interface CMSProps {
    pageId?: string
}

export const CMS: React.FC<CMSProps> = (props) => {
    const { urlPath } = useParams()
    const path = props.pageId || urlPath

    const { loading, error, data } = useQuery<GetCMSPage, GetCMSPageVariables>(GetCMSPageQuery,
        { variables: { identifier: path }})

    if (loading) {
        return <Loader/>
    }

    const pageHeader = (
        <StandardTitle title={data!.cmsPage!.title ?? ''} line={HideLine.false} loading={loading}/>
    )

    return (
        <FullHeightContainer>
            {pageHeader}
            {error && <strong>{error}</strong>}
            {data &&
            <React.Fragment>
                <div className={"page-main"}>
                    <div dangerouslySetInnerHTML={{__html: data.cmsPage?.content ?? ''}}/>
                </div>
            </React.Fragment>
            }
        </FullHeightContainer>
    )

}

/*
 <style>
 @import url(/static/css/m2-style.css);
 @import url(/static/css/m2-style-m.css);
 </style>
*/
