import {gql} from "apollo-boost"

export const ImpersonateMutation = gql`
    mutation Impersonate($folioNumber: String!) {
        paf_impersonateCustomer(deliveryFolio: $folioNumber) {
            paf_folio_number
            paf_folio_type
            token
        }
    }
`
