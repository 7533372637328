import React, {useState} from 'react'
import styled from "styled-components"
import {PlpIcons} from "./layout/svg-library"

interface TelNumberProps {
    number: string
    color?: string
}

export const TelNumber: React.FC<TelNumberProps> = (props) => {

    const stroke = props.color ?? "#595959"
    const [formatNumber, setFormatNumber] = useState(props.number)

    if (formatNumber && formatNumber.length === 10) {
        setFormatNumber(
            formatNumber.slice(0, 3) + ' ' + formatNumber.slice(3, 6) + ' ' + formatNumber.slice(6, 10)
        )
    }

    return (
        <TelNumberContainer>
            {PlpIcons.adminPhone(stroke)}
            &nbsp;<a href={`tel:${props.number}`}>{formatNumber}</a>
        </TelNumberContainer>
    )

}


const TelNumberContainer = styled.div`
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    a {
        color: #595959 !important;
    }
`
