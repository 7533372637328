import React, {useEffect} from 'react'
import { Input } from 'antd'
import 'antd/es/input/style'
import { useHistory } from "react-router-dom"
interface SearchControlProps {
    isVisible: boolean
    width: number
}

export const SearchControl: React.FC<SearchControlProps> = (props) => {

    const history = useHistory()

    const searchOnType = (query: string) => {
        if (query.length < 100) { return }
        history.push(`/search/${query}`)
    }

    const searchOnEnter = (query: string) => {
        history.push(`/search/${query}`)
    }

    useEffect(() => {
        return history.listen((location, goBack) => {
            if (goBack === "POP") {
                history.goBack()
            }
        })
    },[history])

    return (
        <Input.Search
            placeholder="Search"
            className={props.isVisible ? "searchBar open" : "searchBar closed"}
            onChange={(e) => searchOnType(e.target.value)}
            onSearch={(value) => searchOnEnter(value)}
            style={{width: props.width}}
        />
    )

}
