import { gql } from "apollo-boost"
import {CommonCart} from "./CommonCartFragment"

export const MergeGuestCartMutation = gql`
    mutation MergeCarts($sourceCartId: String!, $customerCartId: String!) {
        mergeCarts(source_cart_id: $sourceCartId, destination_cart_id: $customerCartId) {
            ...CommonCart
        }
    }
    ${CommonCart}
`
