import React, {useContext, useEffect, useState} from 'react'
import styled from "styled-components"
import {Layout} from 'antd'
import "../../styles/style.css"
import {socialIcons} from "./svg-library"
import {TelNumber} from "../tel-number"
import {madcdn} from "../../services/image"
import {SessionContext} from "../../contexts/session"
import DefaultImage from "../../assets/icons/UserProfile@2x.png"

interface FooterProps {
}

export const Footer: React.FC<FooterProps> = (props) => {
    const sessionContext = useContext(SessionContext)
    const currentYear = new Date().getFullYear()

    const hasSalesAdvisors = sessionContext.state.isLoggedIn
        && sessionContext.state.salesAdvisors
        && sessionContext.state.salesAdvisors.length > 0

    const hasPhoneNumber = sessionContext.state.isLoggedIn
        && sessionContext.state.salesAdvisors
        && sessionContext.state.salesAdvisors.length > 0
        && sessionContext.state.salesAdvisors[0].phone

    const [isDefaultImage, setIsDefaultImage] = useState(false)

    useEffect(() => {
        setIsDefaultImage(false)
    }, [sessionContext.state.salesAdvisors])

    const handleImageError = (e) => {
        e.target.src = DefaultImage
        setIsDefaultImage(true)
    }

    return (
        <React.Fragment>
            <Layout.Footer style={{padding: 0}}>
                <FooterBottomBar>
                    <div>
                        <div>
                            <h3>Need Assistance?</h3>
                            <p>Please feel free to contact your Sales Advisor if you need any assistance or have any questions.</p>
                        </div>
                        <div>
                            {hasSalesAdvisors &&
                                <>
                                    <ProfileImage
                                        isDefault={isDefaultImage}
                                        onError={handleImageError}
                                        src={madcdn(sessionContext.state.salesAdvisors[0].image, 52, 52)}
                                    />
                                    <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                                        <p><strong>{sessionContext.state.salesAdvisors[0].name}</strong></p>
                                        <a href={`mailto:${sessionContext.state.salesAdvisors[0].email}`}>{sessionContext.state.salesAdvisors[0].email}</a>
                                        {hasPhoneNumber && <TelNumber number={sessionContext.state.salesAdvisors[0].phone} color={'white'}/>}
                                    </div>
                                </>}
                        </div>
                        <div>
                            <p>
                                <a href={"https://www.facebook.com/JOCKDogFood/"} target={"new"}>{socialIcons.facebook}</a>
                                <a href={"https://www.instagram.com/jockdogfood/"} target={"new"}>{socialIcons.instagram}</a>
                                <a href={"https://www.youtube.com/channel/UCd2NQu-qROJ_GgciCR68ZGQ"} target={"new"}>{socialIcons.youTube}</a>
                            </p>
                            <p><a href={"/page/terms-of-use-and-privacy-policy"}>Privacy Policy</a></p>
                            <p><a href={"/page/general-conditions-of-supply"}>Terms of Service</a></p>
                        </div>
                    </div>
                </FooterBottomBar>
                <Copyright>
                    <div>&copy; {currentYear} JOCK. All Rights Reserved.</div>
                </Copyright>
            </Layout.Footer>
        </React.Fragment>
    )
}

const FooterBottomBar = styled.div`
    height: 140px;
    background-color: #003560;
    display: flex;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    color: #FFFFFF;
     @media (max-width: 920px) {
        height: 200px;
    }
    @media (max-width: 767px) {
        height: 350px;
    }
    >div {
        max-width: 1440px;
        display: flex;
        margin: auto;
        width: 100%;
        justify-content: space-between;
        @media (max-width: 1520px) {
            padding: 0 80px;
        }
        @media (max-width: 900px) {
            padding: 0 40px;
        }
        @media (max-width: 767px) {
            padding: 0 18px;
            display: block;
        }
        h3 {
            display: block;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.6px;         
            color: #FFFFFF;
            margin-bottom: 9px;
        }
        p {
            margin: 0;
        }
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }
        a {
            font-size: 14px;
            letter-spacing: 0.6px;
            color: #FFFFFF !important;
        }
        >div {
            max-width: 350px;
            flex: 1;
            :first-child { 
                padding-right: 10px;
            }
            @media (max-width: 767px) {
                max-width: 100%;
                margin-bottom: 48px;
                :last-child {
                    margin-bottom: 0;
                }
            }
        }
        >div:nth-child(2) {
            display: flex;
        }
        >div:last-child {
            text-align: right;
            max-width: 200px !important;
            @media (max-width: 767px) {
                text-align: left;
            }
            p:first-child a {
                margin-left: 10px;
                margin-bottom: 5px;
                display: inline-block;
                @media (max-width: 767px) {
                    :first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
`
const Copyright = styled.div`
    background-color: #E6EBEF;
    height: 40px;
    display: flex;
    align-items: center;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #023560;
    div {
        max-width: 1440px;
        display: flex;
        margin: auto;
        width: 100%;
        p {
            margin: 0;
        }
        a {
            font-size: 14px !important;
            line-height: 20px !important;
            letter-spacing: 0.2px !important;
            color: #8BA2B6 !important;
            margin-left: 15px !important;
        }
        @media (max-width: 1520px) {
            padding: 0 80px;
        }
        @media (max-width: 900px) {
            padding: 0 40px;
        }
        @media (max-width: 767px) {
            padding: 0 18px;
        }
    }
`
const ProfileImage = styled.img<{ isDefault: boolean }>`
    width: 52px;
    height: 52px;
    border-radius: 52px;
    object-fit: cover;
    object-position: center;
    margin: 8px 20px 0 0;
    background-color: #FFFFFF;
    padding: ${props => props.isDefault ? "5px" : "0px"};
`
