   import React, {Suspense, lazy} from 'react'
import ReactDOM from 'react-dom'
import {ApolloProvider} from '@apollo/react-hooks'
import './styles/style.css'
import './styles/colour'
import {Layout} from "./components/layout/layout"
import {Router, Route, Switch} from "react-router"
import {SessionProvider} from "./contexts/session"
import {ConfigProvider} from "./contexts/config"
import {ApolloGraphQLClient} from './graphql/apollo'
import {PaymentComplete} from "./components/pages/payment-complete"
import ReactGA from 'react-ga'
import './services/googleAnalytics'
import {createBrowserHistory} from 'history'
import {ServiceWorkerProvider} from "./services/service-worker"
import {CMS} from "./components/pages/cms"

const Bag = lazy(() => import('./components/pages/bag'))
const Landing = lazy(() => import("./components/pages/landing"))
const Checkout = lazy(() => import("./components/pages/checkout"))
const Countries = lazy(() => import("./components/pages/countries"))
const ProductResolver = lazy(() => import("./components/product-resolver"))
const URLResolver = lazy(() => import("./components/pages/url-resolver"))
const Search = lazy(() => import("./components/pages/search"))
const MyAccount = lazy(() => import("./components/pages/my-account"))
const ResetPass = lazy(() => import('./components/pages/forgot-password-reset'))
const history = createBrowserHistory()

// Initialize Google Analytics page view tracking
history.listen(location => {
    ReactGA.set({page: location.pathname}) // Update the user's current page
    ReactGA.pageview(location.pathname) // Record a page view for the given page
})

ReactDOM.render(
    <ServiceWorkerProvider>
        <ApolloProvider client={ApolloGraphQLClient}>
            <ConfigProvider>
                <SessionProvider>
                    <Router history={history}>
                        <Layout>
                            <Suspense fallback={<span>loading…</span>}>
                                <Switch>
                                    <Route exact path={"/"}>
                                        <Landing/>
                                    </Route>
                                    <Route path={"/checkout"}>
                                        <Checkout/>
                                    </Route>
                                    <Route path={"/bag"}>
                                        <Bag/>
                                    </Route>
                                    <Route path={"/countries"}>
                                        <Countries/>
                                    </Route>
                                    <Route path={"/product/:urlKey+"}>
                                        <ProductResolver/>
                                    </Route>
                                    <Route path={"/category/:urlPath+"}>
                                        <URLResolver/>
                                    </Route>
                                    <Route path={"/search/:query?"}>
                                        <Search/>
                                    </Route>
                                    <Route path={"/my-account"}>
                                        <MyAccount/>
                                    </Route>
                                    <Route path={"/reset-password/:token"}>
                                        <ResetPass/>
                                    </Route>
                                    <Route path={"/payment/complete/:data"}>
                                        <PaymentComplete/>
                                    </Route>
                                    <Route path={"/page/:urlPath+"}>
                                        <CMS/>
                                    </Route>
                                </Switch>
                            </Suspense>
                        </Layout>
                    </Router>
                </SessionProvider>
            </ConfigProvider>
        </ApolloProvider>
    </ServiceWorkerProvider>,
    document.getElementById('root')
)

window.addEventListener('error', async (err) => {
    console.error(err)

    if (!navigator.serviceWorker) {
        return
    }

    if (process.env.NODE_ENV !== 'development') {
        const registration = await navigator.serviceWorker.ready
        if (registration.installing || registration.waiting) {
            navigator.serviceWorker.ready.then(async (registration) => {
                await registration.unregister()
                window.location.reload()
            })
        }
    }
})
