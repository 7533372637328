import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import styled from "styled-components"
import {Drawer, Button, Menu, Dropdown, Modal, message, notification, Alert} from 'antd'
import '../../styles/style.css'
import {HeaderIcons, controlIcons} from './svg-library'
import {Link, useHistory} from 'react-router-dom'
import {CartActions, CartContext} from "../../contexts/cart"
import {SessionActions, SessionContext, SessionStorageKeys} from "../../contexts/session"
import {Login} from "../pages/login"
import {SearchControl} from "../search-control"
import {
    GetCartQuantities,
    GetCartQuantities_cart_items,
    GetCartQuantitiesVariables
} from "../../graphql/requests/types/GetCartQuantities"
import {useLazyQuery, useMutation} from "@apollo/react-hooks"
import GetCartQuantitiesQuery from "../../graphql/requests/GetCartQuantities"
import {CreateNewCart} from "../../graphql/requests/types/CreateNewCart"
import CreateNewCartMutation from "../../graphql/requests/CreateNewCart"
import {History} from "history"
import {Impersonator} from "../impersonator"
import config from  "../../config"
import {useServiceWorker} from "../../services/service-worker"

const {SubMenu} = Menu

const navigationItems = [
    {
        id: 'products', url: '/category/all-products', title: 'Products', subItems: []
    },
]

const navigationControls = [
    {id: 100, type: 'impersonate'},
    {id: 0, type: 'search'},
    {id: 1, type: 'profile'},
    {id: 2, type: 'cart'},
]

const showBagCount = true

const currentWidth = window.innerWidth

let searchWidth

if (currentWidth > 720) {
    searchWidth = 200
} else {
    searchWidth = '100%'
}

enum ShowCookieConsent {
    false,
    true,
}

export const Header = () => {
    const sessionContext = useContext(SessionContext)
    const cartContext = useContext(CartContext)
    const cartContextRef = useRef(cartContext)
    const {state, dispatch} = useContext(SessionContext)
    const sessionContextRef = useRef(sessionContext)

    const { isUpdateAvailable, updateAssets } = useServiceWorker()
    const [loadCartQty, {data, error}] = useLazyQuery<GetCartQuantities, GetCartQuantitiesVariables>(GetCartQuantitiesQuery, {variables: {cartID: cartContext.state.cartID}})

    const [createCart] = useMutation<CreateNewCart>(CreateNewCartMutation)
    const [visible, setVisible] = useState(false)
    const [isShowingSearch, setIsShowingSearch] = useState(false)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [showImpersonateModal, setShowImpersonateModal] = useState(false)

    const [currentPage, setCurrentPage] = useState(null)
    const history = useHistory<History>()
    const [isPDP, setIsPDP] = useState(false)

    const bypassLoginModal = useRef(false)
    const mustLogin = config.features.mustLogin
    const showCookieConsentBanner =localStorage.getItem(SessionStorageKeys.cookieConsent) !== '0'

    const showLoginGate = useCallback(() => {
        if (bypassLoginModal.current) { return }
        if (!mustLogin) { return }
        if (sessionContext.state.isLoggedIn) { return }
        if (!showLoginModal) {
            sessionContext.dispatch({type: SessionActions.setShowLoginModal, payload: true})
        }
    }, [showLoginModal, mustLogin, sessionContext])

    const setLocationPath = useCallback((location: any) => {
        setIsPDP(location.pathname.startsWith('/product'))
        bypassLoginModal.current = location.pathname.startsWith('/reset-password') || location.pathname.startsWith('/page')

        showLoginGate()

        const isSearch = location.pathname.startsWith('/search')
        if (isSearch) { return }
        if (currentPage === null) { return }
        if (currentPage !== location.pathname && isShowingSearch) {
            toggleIsShowingSearch()
        }
    }, [currentPage, isShowingSearch, showLoginGate])

    useEffect(() => {
        if (!isUpdateAvailable) { return }
        console.log('[PWA] A new version is available.')
        notification.open({
            key: 'sw-update',
            message: 'Update available',
            description: (
                <span>
                    A new version of this app is available.
                    <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: "flex-end", marginTop: 7 }}>
                        <Button type={"primary"} onClick={updateAssets} className={"mad-small"}>
                            Update Now
                        </Button>
                    </div>
                </span>
            ),
            placement: "bottomRight",
            duration: 30
        })
    }, [isUpdateAvailable, updateAssets])

    useEffect(() => {
        history.listen((location, action) => {
            setLocationPath(location)
        })
        setLocationPath(history.location)
        // eslint-disable-next-line
    }, [isShowingSearch, currentPage])

    useEffect(() => {
        if (!isShowingSearch) {
            setCurrentPage(history.location.pathname)
        }
    }, [isShowingSearch, history.location.pathname])

    useEffect(() => {
        if (sessionContext.state.isLoggedIn) { return }
        setShowLoginModal(sessionContext.state.showLoginModal)
    }, [sessionContext.state.isLoggedIn, sessionContext.state.showLoginModal])

    useEffect(() => {
        if (!data) {
            return
        }
        if (!data.cart) {
            return
        }

        const qty = (data.cart.items ?? [])
            .filter((item: GetCartQuantities_cart_items | null) => item !== null)
            .map(item => item?.quantity ?? 0)
            .reduce((total: number, qty: number) => total + qty, 0)
        cartContextRef.current.dispatch({type: CartActions.SetCartQty, payload: qty})
    }, [data])

    useEffect(() => {
        if (data) {
            return
        }
        if (!cartContext.state.cartID) {
            return
        }
        loadCartQty()
    }, [loadCartQty, data, cartContext.state.cartID])

    useEffect(() => {
        if (error?.graphQLErrors[0]?.extensions?.category === "graphql-no-such-entity") {
            createCart()
                .then(cartResult => {
                    if (cartResult?.data?.createEmptyCart) {
                        cartContextRef.current.dispatch({
                            type: CartActions.SetCartID,
                            payload: cartResult.data.createEmptyCart
                        })
                    }
                })
        }
    }, [createCart, error])

    useEffect(() => {
        if (!sessionContext.state.isImpersonating && sessionContext.state.isSalesAdvisor) {
            setShowImpersonateModal(true)
        }
    }, [sessionContext.state.isImpersonating, sessionContext.state.isSalesAdvisor])

    const toggleIsShowingSearch = () => {
        setIsShowingSearch(currentState => !currentState)
    }

    const showDrawer = () => {
        setVisible(true)
    }

    const onClose = () => {
        setVisible(false)
    }
    let current = 'mail'

    const handleClick = (e) => {
        current = e.key
        onClose()
    }
    const cartItems = cartContext.state.cartQty
    const cartItemsTag = cartContext.state.cartQty > 99 ? '99+' : `${cartContext.state.cartQty}`

    const bagAmountSize = cartItems > 99 ? 25 : 18

    const navItems = navigationItems.map((item) => {
        const hasSubMenu = item.subItems.length
        if (hasSubMenu) {
            const subItems = item.subItems.map((subItem) => (
                <Menu.Item key={subItem.id}>
                    <Link to={subItem.url}>{subItem.title}</Link>
                </Menu.Item>
            ))
            return (
                <SubMenu key={item.id} title={item.title}>
                    <Menu.ItemGroup>
                        {subItems}
                    </Menu.ItemGroup>
                </SubMenu>
            )
        } else {
            return (
                <Menu.Item key={item.id}>
                    <Link to={item.url}>
                        <span style={{color: "#00365F"}}>{item.title}</span>
                        <div/>
                    </Link>
                </Menu.Item>
            )
        }
    })

    const controlMobileModal = useCallback((show: boolean, modalStateFunc: (show: boolean) => void) => {
        modalStateFunc(show)
        if (!show) {
            //TODO: Fix this amazing hack.
            setTimeout(() => {
                if (document.getElementsByTagName("body")[0].style.overflow === 'hidden') {
                    document.getElementsByTagName("body")[0].style.overflow = 'visible'
                }
            }, 500)
        }
        sessionContextRef.current.dispatch({ type: SessionActions.setShowLoginModal, payload: false })
    }, [])

    const accountMenu = {
        loggedIn: (
            [
                <Menu.Item key={"account"}>
                    <Link to={"/my-account"}> My Account </Link>
                </Menu.Item>,
                <Menu.Item key={"orders"}>
                    <Link to={"/my-account/orders"}> Orders </Link>
                </Menu.Item>,
                <Menu.Item key={"help"}>
                    <Link to={"/"}> Help </Link>
                </Menu.Item>,
                <Menu.Item key={"logout"}>
                    <span onClick={() => {
                        dispatch({type: SessionActions.logOut})
                        message.success('You have successfully signed out.')
                        history.push("/")
                    }}>
                        Sign Out
                    </span>
                </Menu.Item>
            ]
        ),
        loggedOut: (
            [
                <Menu.Item key={"signin"}>
                    <span onClick={() => setShowLoginModal(true)}> Sign In </span>
                </Menu.Item>,
                <Menu.Item key={"help"}>
                    <Link to={"/"}>Help</Link>
                </Menu.Item>
            ]
        )
    }

    const controlItems = navigationControls
        .filter((item) => {
            if (item.type === 'impersonate') {
                return sessionContext.state.isSalesAdvisor
            } else {
                return true
            }
        })
        .map((item) =>
            <div key={item.id}>
                {item.type === 'search' &&
                <ControlIcon>
                    <ClickEventTarget onClick={toggleIsShowingSearch}/>
                    {HeaderIcons.search}
                    {isShowingSearch &&
                    <SearchWrap>
                        <SearchControl isVisible={true} width={searchWidth}/>
                    </SearchWrap>
                    }
                </ControlIcon>
                }
                {item.type === 'profile' &&
                <ControlIcon id="login-button">
                    <Dropdown
                        overlay={<Menu>{state.isLoggedIn ? accountMenu.loggedIn : accountMenu.loggedOut}</Menu>}
                        placement={"bottomRight"}
                        overlayClassName={"menuDropdown"}
                    >
                        {HeaderIcons.userProfile}
                    </Dropdown>
                </ControlIcon>
                }
                {item.type === 'cart' &&
                <Link to={"/bag"}>
                    <ControlIcon>
                        {showBagCount &&
                        <>
                            {cartItems > 0 &&
                                <span style={{
                                    width: bagAmountSize,
                                    height: bagAmountSize
                                }}>
                                    {cartItemsTag}
                                </span>}
                            {HeaderIcons.cart}
                        </>
                        }
                        {!showBagCount &&
                        <>
                            {HeaderIcons.cart}
                        </>
                        }
                    </ControlIcon>
                </Link>
                }
                {item.type === 'impersonate' &&
                <ControlIcon onClick={() => setShowImpersonateModal(true)} style={{cursor: 'pointer'}}>
                    {HeaderIcons.impersonate}
                </ControlIcon>
                }
            </div>
        )

    const onCloseLogin = () => {
        if (!mustLogin && showLoginModal) {
            controlMobileModal(false, setShowLoginModal)
        }
    }

    const storeCookieConsent = () => {
        localStorage.setItem(SessionStorageKeys.cookieConsent, ShowCookieConsent.false.toString())
    }

    const cookieMessage = <span>We use cookies to ensure you get the best possible experience. By using this site we assume your consent, view our <a href={'/page/terms-of-use-and-privacy-policy'}>Privacy Policy</a> for more info.</span>

    return (
        <>
            {showCookieConsentBanner && <Alert
                message={cookieMessage}
                banner
                closable
                showIcon={false}
                className={"cookieBanner"}
                afterClose={storeCookieConsent}
            />}
            {sessionContext.state.isImpersonating &&
            <ImpersonatorBar>
                You are currently logged in as&nbsp;
                <strong>{sessionContext.state.isImpersonating.name} ({sessionContext.state.isImpersonating.folio})</strong>
            </ImpersonatorBar>}
            <Container>
                <Desktop>
                    <Logo>
                        <Link to={"/"}>
                            <img src={require('../../assets/images/logo@2x.png')} alt="logo"/>
                        </Link>
                    </Logo>
                    <MenuArea>
                        <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" className={"nav-menu"}>
                            {navItems}
                        </Menu>
                    </MenuArea>
                    <ControlsArea>
                        {controlItems}
                    </ControlsArea>
                </Desktop>
                <Mobile isImpersonator={sessionContext.state.isSalesAdvisor}>
                    {isPDP &&
                    <>
                        <div
                            style={{color: ' #353C43', marginLeft: 20, cursor: "pointer"}}
                            onClick={() => history.goBack()}
                        >
                            {controlIcons.returnChevronSmall}
                        </div>
                        <h1 dangerouslySetInnerHTML={{__html: state.pageName}}/>
                    </>
                    }
                    {!isPDP &&
                    <>
                        <Button type="primary" onClick={showDrawer}>
                            <div>
                                <span/>
                                <span/>
                                <span/>
                            </div>
                        </Button>
                        <Logo>
                            <Link to={"/"}>
                                <img src={require('../../assets/images/logo@2x.png')} alt="logo"/>
                            </Link>
                        </Logo>
                    </>
                    }
                    <ControlsArea>
                        {controlItems}
                    </ControlsArea>
                    <Drawer
                        title="Menu"
                        placement="left"
                        closable={true}
                        onClose={onClose}
                        visible={visible}
                        width={'100vw'}
                    >
                        <MenuArea>
                            <span style={{color: '#BDBDBD'}}>Product menu</span>
                            <Menu onClick={handleClick} selectedKeys={[current]} mode="vertical">
                                {navItems}
                            </Menu>
                            <span style={{color: '#BDBDBD'}}>Account</span>
                            <Menu onClick={handleClick} selectedKeys={[current]} mode="vertical">
                                {state.isLoggedIn ? accountMenu.loggedIn : accountMenu.loggedOut}
                            </Menu>
                        </MenuArea>
                    </Drawer>
                </Mobile>

                <Modal
                    visible={showLoginModal}
                    onCancel={onCloseLogin}
                    className={"loginModal"}
                    footer={null}
                    destroyOnClose={true}
                    closable={!mustLogin && false}
                >
                    <Login showContainer={(show: boolean) => controlMobileModal(show, setShowLoginModal)}/>
                </Modal>

                <Modal
                    visible={showImpersonateModal}
                    onCancel={onCloseLogin}
                    title={"Which store would you like to log in as?"}
                    footer={null}
                    destroyOnClose={true}
                    closable={!mustLogin && false}
                >
                    <Impersonator showContainer={(show: boolean) => controlMobileModal(show, setShowImpersonateModal)}/>
                </Modal>
            </Container>
        </>
    )
}

const Container = styled.div`
  z-index: 1000;
  height: 53px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  border-bottom: 1px solid #E1E1E1;
  background-color: #FFF;
  @media only screen and (max-width: 720px) {
    height: 51px;
  }
`

const ImpersonatorBar = styled.div`
    background-color: #00375f;
    height: 24px;
    padding: 4px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Noto Sans, serif;
    @media only screen and (max-width: 440px) {
        display: block;
        height: inherit;
        text-align: center;
        strong {
        display: block;
        }
    }
`

const Desktop = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1440px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  @media only screen and (max-width: 1520px) {
    padding: 0 80px;
  }
  @media only screen and (max-width: 720px) {
    display: none;
    visibility: hidden;
    height: 0;
  }
`

const Mobile = styled.div<{ isImpersonator: boolean }>`
  display: none;
  visibility: hidden;
  height: 0;
  h1 {
    margin: auto;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #353C43;
    position: absolute;
    left: 45px;
    right: ${props => props.isImpersonator ? "140px" : "100px"};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  @media only screen and (max-width: 720px) {
    display: flex;
    align-items: center;
    visibility: visible;
    height: 100%;
    width: 100%;
    position: relative;
    button {
      position: absolute;
      left: 3px;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent !important;
      border-radius: 0;
      padding: 0;
      margin: 0;
      box-shadow: none;
      span {
        display: block;
        width: 18px;
        height: 2px;
        background-color: #353C43;
        border-radius: 2px;
        margin-bottom: 5px;
      }
      span:nth-child(2) {
        width: 14px;
        margin-left: 2px;
      }
      span:last-child{
        margin: 0;
      }
    }
  }
`

const Logo = styled.div`
  width: 46px;
  height: 32px;
  position: absolute;
  left: 0;  
  img {
    width: 100%;
    height: auto;
  }
  @media only screen and (max-width: 1520px) {
    left: 80px;    
  }
  @media only screen and (max-width: 720px) {
    left: 66px;    
  }
`

const MenuArea = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 18px 0 0;
  margin: 0;
  @media only screen and (max-width: 720px) {
    display: block;
    padding: 0;
  }
`

const ControlsArea = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0 27px;
  border-left: 1px solid #E1E1E1;
  >div {
    cursor: pointer;
    margin-right: 21px;
    :last-child{
      margin: 0;
    }
  }
  @media only screen and (max-width: 720px) {
    position: absolute;
    right: 28px;
    border-left: none;
    >div {
      :nth-child(2) {
        display: none;
        visibility: hidden;
        width: 0;
        margin: 0;
      }
    }
  }
  //remove :nth-child(1) selector to show search icon on mobile

`

const ControlIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  svg {
    height: 19px;
    width: auto;
  }
  >span {
    width: 25px;
    height: 25px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 45%;
    left: 60%;
    border-radius: 50%;
    background-color: #00365F;
    font-family: Noto Sans;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
  }
`

const SearchWrap = styled.div`
    width: 200px;
    box-sizing: border-box;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.06);
    position: absolute;
    top: 35px;
    right: -1px;
    @media only screen and (max-width: 720px) {
      background-color: #FFF;
      width: 100vw;
      height: 47px;
      right: -69px;
    }
    div {
      width: 100% !important;
    }
`

// const AccountLink = styled.a`
//   height: 51px;
//   padding: 0;
//   margin: 0;
//   display: flex;
//   align-items: center;
//   box-sizing: border-box;
//   border-bottom: 1px solid #F2F2F2;
//   font-family: Georgia;
//   font-size: 15px;
//   color: #6E6E77;
//   text-transform: capitalize;
// `

const ClickEventTarget = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  box-shadow: none !important;
`
