import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import {MiniLoader} from "./loader"

export enum HideLine {
    true,
    false,
}

interface PlpTitleProps {
    title: string
    line?: HideLine|undefined
    loading?: boolean
}

export const StandardTitle: React.FC<PlpTitleProps> = (props) => {

    const {line, loading} = props
    const [hideLine, setHideLine] = useState(HideLine.false)

    useEffect(() => {
        if (line !== undefined) {
            setHideLine(line)
        }
    }, [line])

    return (
        <StyledPlpTitle line={hideLine}>
            <div>
                <h1 dangerouslySetInnerHTML={{__html: props.title}} />
                {loading && <MiniLoader size={20} padding={20}/>}
            </div>
            {(hideLine !== HideLine.true) && <Line/>}
        </StyledPlpTitle>
    )
}

const StyledPlpTitle = styled.div<{ line: HideLine }>`
  position: relative;
  width: 100%;
  height: ${props => props.line ? "111px" : "40px"};    
  display: flex;
  align-items: center;    
  div:first-child {
    display: flex;
    align-items: center;
  }
  h1 {
    font-family: Georgia;
    font-size: 21px;
    color: #003660;
    text-transform: capitalize;
    background-color: #FFF;
    padding: 8px 20px 0 0;
    display: block;
    width: max-content;
  }
`

const Line = styled.div`
    margin-top: 20px;   
    width: 100%;
    height: 0;
    border-bottom: 1px solid rgba(59, 59, 59, 0.15); 
`
