import { gql } from 'apollo-boost'

export default gql`
    mutation GenerateCustomerToken($email: String!, $password: String!) {
        generateCustomerToken(email: $email, password: $password) {
            token
            paf_folio_number
            paf_folio_type
        }
    }
`
