import React, {useContext, useCallback, useEffect, useRef, useState} from 'react'
import {useLazyQuery, useMutation, useQuery} from "@apollo/react-hooks"
import {GetSalesAdvisorStoresQuery} from "../graphql/requests/GetSalesAdvisorStores"
import {
    GetSalesAdvisorStores,
    GetSalesAdvisorStores_paf_salesAdvisorStores
} from '../graphql/requests/types/GetSalesAdvisorStores'
import styled from 'styled-components'
import {Loader} from "./loader"
import {Table, Input} from 'antd'
import {Impersonate, ImpersonateVariables} from "../graphql/requests/types/Impersonate"
import {ImpersonateMutation} from "../graphql/requests/ImpersonateMutation"
import {SessionActions, SessionContext} from "../contexts/session"
import {ApolloGraphQLImpersonatorClient} from "../graphql/apollo-impersonator"
import {AfterSignIn} from "../graphql/requests/types/AfterSignIn"
import {AfterSignInQuery} from "../graphql/requests/AfterSignInQuery"
import {CartActions, CartContext} from "../contexts/cart"
import {GetCartQuantities_cart_items} from "../graphql/requests/types/GetCartQuantities"
import {useHistory} from "react-router-dom"
import {History} from "history"

interface ImpersonatorProps {
    showContainer?: (show: boolean) => void
}

export const Impersonator: React.FC<ImpersonatorProps> = (props) => {
    const {dispatch} = useContext(SessionContext)
    const cartContext = useContext(CartContext)
    const cartContextRef = useRef(cartContext)
    const history = useHistory<History>()

    const { data, loading } = useQuery<GetSalesAdvisorStores>(GetSalesAdvisorStoresQuery, { client: ApolloGraphQLImpersonatorClient })
    const [impersonateCustomer, { called: impersonateCalled }] = useMutation<Impersonate, ImpersonateVariables>(ImpersonateMutation, { client: ApolloGraphQLImpersonatorClient })
    const [getCustomerCart, getCustomerCartData] = useLazyQuery<AfterSignIn>(AfterSignInQuery, { fetchPolicy: "no-cache" })

    const [salesAdvisorStores, setSalesAdvisorStores] = useState<GetSalesAdvisorStores_paf_salesAdvisorStores[]>(null)

    const { showContainer } = props

    const onStoreSelected = useCallback((store: GetSalesAdvisorStores_paf_salesAdvisorStores) => {
        impersonateCustomer({ variables: { folioNumber: store.deliveryFolio } })
            .then(response => {
                dispatch({ type: SessionActions.setImpersonating, payload: { name: store.address.company, folio: response.data.paf_impersonateCustomer.paf_folio_number } })
                dispatch({ type: SessionActions.setLoginToken, payload: response.data.paf_impersonateCustomer! })
                getCustomerCart()
                if (!history.location.pathname.startsWith('/my-account')) {
                    history.push('/')
                }
            })
    }, [dispatch, getCustomerCart, history, impersonateCustomer])

    useEffect(() => {
        if (!getCustomerCartData.data) { return }
        const customerCartId = getCustomerCartData.data.customerCart.id
        const qty = (getCustomerCartData.data.customerCart.items ?? [])
            .filter((item: GetCartQuantities_cart_items|null) => item !== null)
            .map(item => item?.quantity ?? 0)
            .reduce((total: number, qty: number) => total + qty, 0)

        cartContextRef.current.dispatch({ type: CartActions.SetCartID, payload: customerCartId })
        cartContextRef.current.dispatch({ type: CartActions.SetCartQty, payload: qty })
        dispatch({ type: SessionActions.setSalesAdvisors, payload: getCustomerCartData.data.paf_salesAdvisors })
        showContainer(false)
    }, [getCustomerCartData.data, showContainer, dispatch])

    useEffect(() => {
        if (data) {
            setSalesAdvisorStores(data.paf_salesAdvisorStores)
        }
    }, [data])

    if (loading || !data || impersonateCalled) {
        return (
            <Container>
                <Loader />
            </Container>
        )

    }
    const handleSearch = (text) => {
        const searchValue = text.toLowerCase()
        if (searchValue.length > 0) {
            let stores: GetSalesAdvisorStores_paf_salesAdvisorStores[]
            const isNum = text.match(/[0-9]/g)
            if (isNum) {
                stores = data.paf_salesAdvisorStores.filter((account) => account.deliveryFolio.includes(searchValue))
            } else {
                stores = data.paf_salesAdvisorStores.filter((account) => {
                    const fullAddress = account.address.company.concat(
                        account.address.street[0], account.address.street[1], account.address.city
                    )
                    const found = fullAddress.toLowerCase().includes(searchValue)
                    return found && account
                })
            }
            setSalesAdvisorStores(stores)
        } else {
            setSalesAdvisorStores(data.paf_salesAdvisorStores)
        }
    }

    const columns = [
        {
            title: 'Folio No.',
            dataIndex: 'deliveryFolio',
            key: 'deliveryFolio',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            render: (address) => {
                if (address) {
                    return (
                        <span key={address.company}>
                            {[address.company, address.street.join(' ,'), address.city, address.region]
                                .filter(detail => detail && detail.length > 0)
                                .join(', ')}
                        </span>
                    )
                }
            },
        }
    ]
    return (
        <>
            <Input
                placeholder="input search text"
                style={{ marginBottom: 20, padding: 0 }}
                onChange={value => handleSearch(value.target.value)}
            />
            <Table
                className={'store-impersonator'}
                dataSource={salesAdvisorStores}
                columns={columns}
                rowKey={(record) => record.deliveryFolio}
                onRow={(record) => {
                    return {
                        onClick: () => onStoreSelected(record)
                    }
                }}
                pagination={false}
            />
        </>
    )
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 500px;
`
