import {gql} from "apollo-boost"

export const CommonCart = gql`
    fragment CommonCart on Cart {
        id
        applied_coupons {
            code
        }
        items {
            id
            quantity
            prices {
                row_total_including_tax {
                    value
                }
            }
            product {
                stock_status
                jock_info {
                    range
                }
                name
                sku
                price_range {
                    maximum_price {
                        regular_price {
                            currency
                            value
                        }
                    }
                }
                price_tiers {
                    final_price {
                        currency
                        value
                    }
                }
                small_image {
                    label
                    url
                }
            }
            
        }
        prices {
            applied_taxes {
                amount {
                    value
                }
                label
            }
            grand_total {
                currency
                value
            }
            subtotal_excluding_tax {
                value
            }
            subtotal_including_tax {
                value
            }
            discounts {
                amount {
                    value
                }
                label
            }
        }
    }
`
