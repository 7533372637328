import React, {useEffect, useState} from 'react'
import {StoreConfig, StoreConfig_currency, StoreConfig_storeConfig} from "../graphql/requests/types/StoreConfig"
import {useQuery} from "@apollo/react-hooks"
import {GetStoreConfig} from "../graphql/requests/GetStoreConfig"
import {Loader} from "../components/loader"

interface ConfigContextInterface {
    storeConfig: StoreConfig_storeConfig|null
    currency: StoreConfig_currency|null
}

const ConfigContext = React.createContext<ConfigContextInterface>({ storeConfig: null, currency: null })

const LocalStorageConfigKey = "m2.store.config"

const ConfigProvider: React.FC = (props) => {
    const { loading, error, data } = useQuery<StoreConfig>(GetStoreConfig)
    const [storeConfig, setStoreConfig] = useState<ConfigContextInterface|null>(null)

    useEffect(() => {
        if (data) { return }
        const rawLocalStoreConfig = localStorage.getItem(LocalStorageConfigKey)
        if (rawLocalStoreConfig) {
            const localStoreConfig = JSON.parse(rawLocalStoreConfig)
            setStoreConfig(localStoreConfig)
        } else {
            setStoreConfig(null)
        }
    }, [data])

    useEffect(() => {
        if (data && !storeConfig) {
            localStorage.setItem(LocalStorageConfigKey, JSON.stringify(data))
            setStoreConfig(data)
        }
    }, [data, storeConfig])

    if (loading) {
        return <Loader />
    }

    if (error) {
        console.error(error)
        return null
    }

    if (!storeConfig) {
        return null
    }

    return (
        <ConfigContext.Provider value={storeConfig}>
            {props.children}
        </ConfigContext.Provider>
    )
}

export {
    ConfigContext,
    ConfigProvider,
    LocalStorageConfigKey
}
