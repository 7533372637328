import React, {useContext, useEffect, useState, useRef} from 'react'
import {SessionActions, SessionContext} from '../../contexts/session'
import GenerateCustomerTokenMutation from '../../graphql/requests/GenerateCustomerToken'
import {GenerateCustomerToken, GenerateCustomerTokenVariables} from "../../graphql/requests/types/GenerateCustomerToken"
import {useLazyQuery, useMutation} from "@apollo/react-hooks"
import {Alert, Button, Form, Input, message} from 'antd'
import styled from "styled-components"
import {MergeCarts, MergeCartsVariables} from "../../graphql/requests/types/MergeCarts"
import {MergeGuestCartMutation} from "../../graphql/requests/MergeGuestCart"
import {CartActions, CartContext} from "../../contexts/cart"
import {GetCartQuantities_cart_items} from "../../graphql/requests/types/GetCartQuantities"
import {ForgotPass} from "../forgot-pass"
import {AfterSignIn} from "../../graphql/requests/types/AfterSignIn"
import {AfterSignInQuery} from "../../graphql/requests/AfterSignInQuery"
import Animate from 'rc-animate';

interface LoginViewProps {
    showContainer?: (show: boolean) => void
}

export const Login: React.FC<LoginViewProps> = (props) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isShowingForgotPass, setIsShowingForgotPass] = useState(false)
    const [mergedCartData, setMergedCartData] = useState<MergeCarts>(null)

    const sessionContext = useContext(SessionContext)
    const sessionContextRef = useRef(sessionContext)
    const cartContext = useContext(CartContext)
    const cartContextRef = useRef(cartContext)

    const [getCustomerCart, getCustomerCartData] = useLazyQuery<AfterSignIn>(AfterSignInQuery, { fetchPolicy: "network-only" })
    const [mergeCarts, mergeCartsData] = useMutation<MergeCarts, MergeCartsVariables>(MergeGuestCartMutation)

    const [generateCustomerToken, { data: generateTokenData }] = useMutation<GenerateCustomerToken, GenerateCustomerTokenVariables>(GenerateCustomerTokenMutation, {
        onError: (error) => {
            sessionContextRef.current.dispatch({ type: SessionActions.setLatestError, payload: error.graphQLErrors[0].message })
            setIsLoading(false)
        },
    })

    const toggleIsShowingForgotPass = () => {
        setIsShowingForgotPass(currentState => !currentState)
    }

    const { showContainer } = props

    useEffect(() => {
        if (!generateTokenData) { return }
        sessionContextRef.current.dispatch({ type: SessionActions.setLoginToken, payload: generateTokenData.generateCustomerToken! })
        getCustomerCart()
    }, [generateTokenData, getCustomerCart])

    useEffect(() => {
        if (!getCustomerCartData.data) { return }
        if (!generateTokenData) { return }
        const customerCartId = getCustomerCartData.data.customerCart.id
        if (cartContext.state.cartID === customerCartId) { return }

        mergeCarts({ variables: {
            sourceCartId: cartContext.state.cartID,
            customerCartId
        } })
            .catch(console.error)
            .then((result) => {
                result && setMergedCartData(result.data)
            })
            .finally(() => sessionContextRef.current.dispatch({ type: SessionActions.setSalesAdvisors, payload: getCustomerCartData.data.paf_salesAdvisors }))
    }, [cartContext.state.cartID, generateTokenData, getCustomerCartData.data, mergeCarts])

    useEffect(() => {
        if (!getCustomerCartData.data) { return }
        if (!generateTokenData) { return }
        if (!mergedCartData) { return }

        const qty = (mergedCartData.mergeCarts.items ?? [])
            .filter((item: GetCartQuantities_cart_items|null) => item !== null)
            .map(item => item?.quantity ?? 0)
            .reduce((total: number, qty: number) => total + qty, 0)

        cartContextRef.current.dispatch({ type: CartActions.SetCartID, payload: mergedCartData.mergeCarts.id })
        cartContextRef.current.dispatch({ type: CartActions.SetCartQty, payload: qty })
        message.success('You have successfully signed in.')
        setIsLoading(false)

        if (showContainer) {
            showContainer(false)
        }
    }, [mergedCartData, generateTokenData, showContainer, getCustomerCartData])

    useEffect(() => {
        if (mergeCartsData.error || getCustomerCartData.error) {
            setIsLoading(false)
        }
    }, [mergeCartsData.error, getCustomerCartData.error])

    useEffect(() => {
        if (sessionContext.state.latestError) {
            setTimeout(() => {
                sessionContextRef.current.dispatch({
                    type: SessionActions.setLatestError,
                    payload: null
                })
            }, 10000)
        }
    }, [sessionContext.state.latestError])

    return (
        <React.Fragment>
            <LoginModal>
                <LoginModalClose>
                    <MobileTopControls>
                        <h1>Sign In</h1>
                    </MobileTopControls>
                </LoginModalClose>
                <LoginLogoWrap>
                    <img src={require("../../assets/images/logo@2x.png")} alt="company logo" className={"loginLogo"}/>
                </LoginLogoWrap>
                <LoginWelcomeMessage>
                    Welcome Back
                </LoginWelcomeMessage>
                <Animate
                    transitionName="fade"
                    transitionAppear
                >
                    {sessionContext.state.latestError ? <Alert
                        message={sessionContext.state.latestError}
                        type="error"
                        showIcon
                        style={{margin: "20px 0"}}
                    /> : null}
                </Animate>
                <Form>
                    <Form.Item>
                        <label>FOLIO Number or Email</label>
                        <Input
                            id={"loginEmail"}
                            type="email"
                            value={username}
                            onChange={(field) => setUsername(field.target.value)}
                            style={{ fontSize: 16 }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>Password</label>
                        <Input.Password
                            id={"loginPassword"}
                            type="password"
                            value={password}
                            onChange={(field) => setPassword(field.target.value)}
                            style={{ fontSize: 16 }}
                        />
                    </Form.Item>
                    <Button
                        id={"loginButton"}
                        type={"primary"}
                        htmlType={"submit"}
                        loading={isLoading}
                        disabled={isLoading || username.length === 0 || password.length === 0}
                        onClick={(e) => {
                            e.preventDefault()
                            setIsLoading(true)
                            generateCustomerToken({
                                variables: {
                                    email: username,
                                    password,
                                }
                            })
                        }}
                    >
                        {isLoading ? "Signing in…" : "Sign in"}
                    </Button>
                </Form>
                <p style={{textAlign: "center"}}>By clicking Sign in you agree to our <a href={"/page/terms-of-use-and-privacy-policy"}>Terms of Use and Privacy Policy</a> as well as our <a href={"/page/general-conditions-of-supply"}>General Conditions of Sale</a></p>
                <button onClick={toggleIsShowingForgotPass}>Forgot password?</button>
            </LoginModal>
            {isShowingForgotPass &&
                <ForgotPass toggleForgotPass={toggleIsShowingForgotPass}/>
            }
        </React.Fragment>
    )
}

export const MobileTopControls = styled.div`
  border: none;
  border-bottom: 1px solid #ECECEE;
  height: 51px;
  display: flex;  
  align-items: center;
  justify-content: center;
  position: relative;
  span {    
    position: absolute;
    left: 10px;    
    
    display: flex;
    align-items: center;
    justify-content: center;
        
    width: 45px;
    height: 45px;
  }
  span img {
    width: 8px;
    height: 14px;
  }
  h1 {
    padding: 0;
    margin: 0;
    font-size: 15px;
    letter-spacing: 1px;
    color: #222222;
  }
`

const LoginModal = styled.div`
  min-width: 320px;
  padding: 20px 40px;
  button {
  cursor: pointer;
  }
  label {
    font-family: Georgia;
    font-style: normal;
    font-weight: normal;
    font-size: 15px !important;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #353C43 !important;
  } 
  .rememberMe {
    font-family: Noto Sans;
    font-size: 14px !important;
    display: inline-block;
    width: 50%;
  } 
  a {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 22px;
    display: block;
    letter-spacing: normal !important;
  } button {
    background-color: transparent;
    border: none;
    width: 100%;
    :active {
      border: none !important;
    }
  }
  Form button {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    height: 44px;
    border-radius: 4px;
    border: none;
    background-color: #11365C;
    margin: 20px 0 24px 0;
    width: calc(100% - 10px);
    :hover,:focus {
      background-color: #11365C;
    }
  }  
  a {
    font-size: 13px !important;
    letter-spacing: 0.4px;
    text-align: center;
    display: inline-block;
    text-decoration: underline;
    color: #6E6E77;
  }
  @media screen and (max-width: 570px) {
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
  }
`

const LoginModalClose = styled.div`
  button {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    width: 13px;
    height: 13px;    
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 24px;
    right: 24px;
    :focus {
      outline: none;
    }
    img {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
    }
  } 
  div {
    display: none;
    visibility: hidden;   
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  } 
  @media screen and (max-width: 570px) {
    button {
      display: none;
      visibility: hidden;
    }  
    div {
      display: flex;
      visibility: visible;
    }
  }
`

const LoginLogoWrap = styled.div`  
  height: 86px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  margin-top: 32px;
  margin-bottom: 12px;  
  img {
    width: 150px;
    height: 86px;
  }
`

const LoginWelcomeMessage = styled.h1`  
    font-family: Georgia;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 32px;
    align-items: center;
    text-align: center;
    color: #222222;   
    margin-bottom: 30px;
`
