import React from 'react'
import { Layout } from 'antd'

interface BodyProps {}

export const Body: React.FC<BodyProps> = (props) => {
    return (
        <Layout.Content className={"main"}>
            {props.children}
        </Layout.Content>
    )
}
