import { gql } from "apollo-boost"
import {CommonCart} from "./CommonCartFragment"

export const AfterSignInQuery = gql`
    query AfterSignIn {
        customerCart {
            ...CommonCart
        }
        paf_salesAdvisors {
            name
            email
            phone
            image
        }
    }
    ${CommonCart}
`
