/* eslint-disable indent */
import React, { useReducer, useEffect, useContext } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { CreateNewCart } from '../graphql/requests/types/CreateNewCart'
import CreateNewCartMutation from '../graphql/requests/CreateNewCart'
import {SessionContext, SessionStorageKeys} from './session'

interface CartState {
    hasCart: boolean
    cartID: string|null
    cartQty: number
    refreshCounter: number
}

const initialState: CartState = {
    hasCart: localStorage.getItem(SessionStorageKeys.shoppingCartId) !== null,
    cartID: localStorage.getItem(SessionStorageKeys.shoppingCartId),
    cartQty: 0,
    refreshCounter: 1
}

export enum CartActions {
    GetCart,
    SetCartID,
    SetCartQty
}

interface Action {
    type: CartActions,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any|null
}

interface CartContextType {
    state: typeof initialState,
    dispatch: (action: Action) => void,
}

const CartContext = React.createContext<CartContextType>({
    state: initialState,
    dispatch: () => {}
})

const reducer = (state: CartState, action: Action) => {
    switch (action.type) {

        case CartActions.GetCart:
            return { ...state, refreshCounter: state.refreshCounter + 1 }

        case CartActions.SetCartQty:
            return { ...state, cartQty: (action.payload as number), refreshCounter: state.refreshCounter + 1 }

        case CartActions.SetCartID: {
            const cartID = (action.payload as string|null)
            if (cartID) {
                localStorage.setItem(SessionStorageKeys.shoppingCartId, cartID)
                return {
                    ...state,
                    hasCart: true,
                    cartID
                }
            } else {
                localStorage.removeItem(SessionStorageKeys.shoppingCartId)
                return {
                    ...state,
                    hasCart: false,
                    cartID: null
                }
            }
        }
    }
}

const CartProvider: React.FC = (props) => {
    const sessionContext = useContext(SessionContext)
    const [state, dispatch] = useReducer(reducer, initialState)
    const [createCart, createCartData] = useMutation<CreateNewCart>(CreateNewCartMutation)

    const value = { state, dispatch }

    useEffect(() => {
        if (!localStorage.getItem(SessionStorageKeys.shoppingCartId)) {
            dispatch({ type: CartActions.SetCartID, payload: null })
        }
    }, [sessionContext.state.isLoggedIn, dispatch])

    useEffect(() => {
        if (!state.hasCart && !sessionContext.state.isLoggedIn && !createCartData.called) {
            createCart()
        }
    }, [state.hasCart, sessionContext, createCart, createCartData.called])

    useEffect(() => {
        if (createCartData && createCartData.data && createCartData.data.createEmptyCart) {
            dispatch({ type: CartActions.SetCartID, payload: createCartData.data.createEmptyCart })
        }
    }, [createCartData])

    useEffect(() => {
        if (!state.cartID) {
            createCart()
        }
    }, [createCart, state.cartID])

    return (
        <CartContext.Provider value={value}>
            {props.children}
        </CartContext.Provider>
    )
}

export {
    CartContext,
    CartProvider
}
