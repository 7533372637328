import React from 'react'
import { Spin } from 'antd'
import {LoadingOutlined} from '@ant-design/icons'

interface LoaderProps {
    size?: number
    padding?: number
}

export const Loader: React.FC<LoaderProps> = (props) => {
    const size = props.size || 70
    const antIcon = <LoadingOutlined style={{ fontSize: size, color: '#11365c' }} spin />

    return (
        <div style={{ display: "flex", alignItems: "center", "justifyContent": "center", height: "calc(100vh - 70px)" }}>
            <Spin indicator={antIcon} />
        </div>
    )
}

export const MiniLoader: React.FC<LoaderProps> = (props) => {
    const size = props.size || 70
    const antIcon = <LoadingOutlined style={{ fontSize: size, color: '#11365c' }} spin />

    return (
        <div style={{ display: "flex", alignItems: "center", "justifyContent": "center", paddingRight: props.padding }}>
            <Spin indicator={antIcon} />
        </div>
    )
}
