import {gql} from "apollo-boost"

export const GetSalesAdvisorStoresQuery = gql`
    query GetSalesAdvisorStores {
        paf_salesAdvisorStores {
            deliveryFolio
            address {
                company
                firstname
                lastname
                street
                city 
                region {
                    region
                }
            }
        }
    }   
`
