import {gql} from "apollo-boost"

export default gql`
    query GetCMSPage($identifier: String!) {
        cmsPage(identifier: $identifier) {
            title    
            content
        }
    }
`
