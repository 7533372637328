import {gql} from 'apollo-boost'

export default gql`
    query GetCartQuantities($cartID: String!) {
      cart(cart_id: $cartID) {
          id
          items {
            quantity
          }
      }
    }   
`
