import React from 'react'
import { useParams } from 'react-router-dom'

interface PaymentCompleteProps {
}

export const PaymentComplete: React.FC<PaymentCompleteProps> = (props) => {
    const { data } = useParams()

    window.parent.postMessage(window.atob(decodeURIComponent(data)), '*')

    return (
        <span />
    )
}
