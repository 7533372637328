import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import Config from "../config"
import {SessionStorageKeys} from "../contexts/session"

const httpLink = createHttpLink({
    uri: Config.graphql.uri,
    credentials: 'same-origin'
})

const authLink = setContext((_, { headers }) => {
    const tokenData = localStorage.getItem(SessionStorageKeys.token)
    let token = ''
    if (tokenData) {
        const customerToken = JSON.parse(tokenData)
        token = customerToken['token']
    }

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
})

export const ApolloGraphQLClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
})
