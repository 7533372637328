import React, {ReactNode, useEffect, useReducer} from 'react'
import {GetCustomer} from "../graphql/requests/types/GetCustomer"
import {AfterSignIn_paf_salesAdvisors} from "../graphql/requests/types/AfterSignIn"
import {GenerateCustomerToken_generateCustomerToken} from "../graphql/requests/types/GenerateCustomerToken"
import {PafFolioType} from "../graphql/global-types"

export type ImpersonationDetails = { folio: string, name: string }

interface SessionState {
    isLoggedIn: boolean
    isSalesAdvisor: boolean
    isImpersonating: ImpersonationDetails|null
    customer: GetCustomer | null
    salesAdvisors: AfterSignIn_paf_salesAdvisors[] | null
    latestError: string | null
    pageName: string | null
    showLoginModal: boolean
    refreshCounter: number
    sessionData: any | null
}

const InitialState: SessionState = {
    isLoggedIn: false,
    isSalesAdvisor: false,
    isImpersonating: null,
    customer: null,
    salesAdvisors: null,
    latestError: null,
    pageName: null,
    showLoginModal: false,
    refreshCounter: 1,
    sessionData: null
}

export enum SessionActions {
    setIsLoggedIn,
    setLoginToken,
    setCustomer,
    setLatestError,
    logOut,
    setPageName,
    setShowLoginModal,
    setSalesAdvisors,
    setImpersonating
}

interface SessionContextAction {
    type: SessionActions
    payload?: any | null
}

interface SessionContextType {
    state: typeof InitialState
    dispatch: (action: SessionContextAction) => void
}

export enum SessionStorageKeys {
    shoppingCartId = "m2.cart.id",
    customer = "m2.customer",
    token = "m2.tokenData",
    salesAdvisors = "m2.salesAdvisors",
    salesAdvisorToken = "m2.salesAdvisor.tokenData",
    impersonatingStore = "m2.impersonation.store",
    cookieConsent = "m2.cookieConsent"
}

const Reducer = (state: SessionState, action: SessionContextAction) => {
    switch (action.type) {
    case SessionActions.setPageName:
        return {...state, pageName: action.payload as string | null}

    case SessionActions.setIsLoggedIn: {
        const salesAdvisorToken = localStorage.getItem(SessionStorageKeys.salesAdvisorToken)
        const isSalesAdvisor = salesAdvisorToken !== null
        if (action.payload !== null) {
            return {...state, isLoggedIn: true, sessionData: JSON.parse(action.payload), isSalesAdvisor}
        } else {
            return {...state, isLoggedIn: false, isSalesAdvisor}
        }
    }

    case SessionActions.setCustomer: {
        localStorage.setItem(SessionStorageKeys.customer, JSON.stringify(action.payload))
        return {...state, customer: action.payload as GetCustomer | null}
    }

    case SessionActions.setLatestError:
        return {...state, latestError: action.payload as string | null}

    case SessionActions.setLoginToken: {
        const token = action.payload as GenerateCustomerToken_generateCustomerToken | null
        if (token) {
            let isSalesAdvisor = false
            let refreshCounter = state.refreshCounter
            localStorage.setItem(SessionStorageKeys.token, JSON.stringify(token))
            if (token.paf_folio_type === PafFolioType.SALESADVISOR) {
                localStorage.setItem(SessionStorageKeys.salesAdvisorToken, JSON.stringify(token))
                isSalesAdvisor = true
            } else {
                const salesAdvisorToken = localStorage.getItem(SessionStorageKeys.salesAdvisorToken)
                isSalesAdvisor = salesAdvisorToken !== null
                refreshCounter = state.refreshCounter + 1
            }
            return {...state, isLoggedIn: true, sessionData: token, isSalesAdvisor, refreshCounter}
        } else {
            localStorage.removeItem(SessionStorageKeys.shoppingCartId)
            localStorage.removeItem(SessionStorageKeys.customer)
            localStorage.removeItem(SessionStorageKeys.token)
            localStorage.removeItem(SessionStorageKeys.salesAdvisorToken)
            localStorage.removeItem(SessionStorageKeys.impersonatingStore)
            return {...state, isLoggedIn: false, isSalesAdvisor: false, isImpersonating: null}
        }
    }

    case SessionActions.setShowLoginModal: {
        return {...state, showLoginModal: action.payload as boolean}
    }

    case SessionActions.logOut: {
        localStorage.removeItem(SessionStorageKeys.shoppingCartId)
        localStorage.removeItem(SessionStorageKeys.customer)
        localStorage.removeItem(SessionStorageKeys.token)
        localStorage.removeItem(SessionStorageKeys.salesAdvisorToken)
        localStorage.removeItem(SessionStorageKeys.salesAdvisors)
        localStorage.removeItem(SessionStorageKeys.impersonatingStore)
        return {...state, isLoggedIn: false, customer: null, salesAdvisors: null, isSalesAdvisor: false, isImpersonating: null}
    }

    case SessionActions.setSalesAdvisors: {
        localStorage.setItem(SessionStorageKeys.salesAdvisors, JSON.stringify(action.payload))
        return {...state, salesAdvisors: action.payload as AfterSignIn_paf_salesAdvisors[] | null}
    }

    case SessionActions.setImpersonating: {
        if (action.payload) {
            localStorage.setItem(SessionStorageKeys.impersonatingStore, JSON.stringify(action.payload))
            return {...state, isImpersonating: action.payload as ImpersonationDetails}
        } else {
            localStorage.removeItem(SessionStorageKeys.impersonatingStore)
            return {...state, isImpersonating: null}
        }
    }
    }
}

const SessionContext = React.createContext<SessionContextType>({
    state: InitialState,
    dispatch: () => {}
})

interface SessionProviderProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: ReactNode
}

const SessionProvider: React.FC<SessionProviderProps> = (props) => {
    const [state, dispatch] = useReducer(Reducer, InitialState)
    const value = {state, dispatch}

    useEffect(() => {
        const token = localStorage.getItem(SessionStorageKeys.token)
        dispatch({type: SessionActions.setIsLoggedIn, payload: token})
    }, [])

    useEffect(() => {
        const customer = localStorage.getItem(SessionStorageKeys.customer)
        if (customer) {
            dispatch({type: SessionActions.setCustomer, payload: JSON.parse(customer)})
        }
        const salesAdvisors = localStorage.getItem(SessionStorageKeys.salesAdvisors)
        if (salesAdvisors) {
            dispatch({type: SessionActions.setSalesAdvisors, payload: JSON.parse(salesAdvisors)})
        }
        const impersonatingStore = localStorage.getItem(SessionStorageKeys.impersonatingStore)
        if (impersonatingStore) {
            dispatch({type: SessionActions.setImpersonating, payload: JSON.parse(impersonatingStore)})
        }
    }, [])

    return (
        <SessionContext.Provider value={value}>
            {props.children}
        </SessionContext.Provider>
    )
}

export {
    SessionContext,
    SessionProvider
}
