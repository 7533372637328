export enum Colour {
    Primary = "#006491",
    Secondary = "#E31837",
    Good = "#8BC34A",
    White = "#FFFFFF",
    LightGrey = "#F9F9FB",
    MediumGrey = "#ECECEE",
    LightMediumGrey = "#F2F2F2",
    MediumDarkGrey = "#D1D2D4",
    DarkHalfOpacity = "#006491",
}


