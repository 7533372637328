import React, {HTMLAttributes} from 'react'
import {Body} from "./body"
import {CartProvider} from "../../contexts/cart"
import { Layout as AntLayout } from 'antd'
import {Header} from "./header"
import {Footer} from "./footer"

export const Layout = (props: HTMLAttributes<HTMLDivElement>) => {
    return (
        <CartProvider>
            <AntLayout className={"layout"}>
                <Header />
                <Body>
                    {props.children}
                </Body>
                <Footer />
            </AntLayout>
        </CartProvider>
    )
}
