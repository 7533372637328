import React, {useContext, useEffect, useMemo, useState} from "react"
import * as serviceWorker from "../serviceWorker"

interface ServiceWorkerContextProps {
    isUpdateAvailable: boolean
    updateAssets: () => void
}

const ServiceWorkerContext = React.createContext<ServiceWorkerContextProps>({
    isUpdateAvailable: false,
    updateAssets: () => {
        console.log('not initialised')
    }
})

export const ServiceWorkerProvider: React.FC = (props) => {
    const [waitingServiceWorker, setWaitingServiceWorker] = useState<ServiceWorker|null>(null)
    const [isUpdateAvailable, setIsUpdateAvailable] = useState(false)

    useEffect(() => {
        serviceWorker.register({
            onUpdate: registration => {
                setWaitingServiceWorker(registration.waiting)
                setIsUpdateAvailable(true)
            },
            onWaiting: waiting => {
                setWaitingServiceWorker(waiting)
                setIsUpdateAvailable(true)
            }
        })
    }, [])

    useEffect(() => {
        if (!waitingServiceWorker) { return }
        waitingServiceWorker.addEventListener('statechange', event => {
            // @ts-ignore
            if (event.target.state === 'activated') {
                window.location.reload()
            }
        })
    }, [waitingServiceWorker])

    const value = useMemo(() => ({
        isUpdateAvailable,
        updateAssets: () => {
            if (waitingServiceWorker) {
                waitingServiceWorker.postMessage({type: 'SKIP_WAITING'})
            }
        }
    }), [isUpdateAvailable, waitingServiceWorker])

    return (
        <ServiceWorkerContext.Provider value={value}>
            {props.children}
        </ServiceWorkerContext.Provider>
    )
}

export const useServiceWorker = () => {
    return useContext(ServiceWorkerContext)
}
