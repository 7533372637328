import React, {useState} from 'react'
import {Alert, Button, Input} from 'antd'
import styled from "styled-components"
import {controlIcons} from "./layout/svg-library";
import config from '../config';
import {SessionStorageKeys} from "../contexts/session"

interface ForgotPassProps {
    toggleForgotPass: any
}

export enum RESTMethods {
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE",
    GET = "GET",
    POST = "POST",
    OPTIONS = "OPTIONS",
}

export const ForgotPass: React.FC<ForgotPassProps> = (props) => {

    const token = localStorage.getItem(SessionStorageKeys.token)
    const [email, setEmail] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState(null)

    const resetPassword = () => {
        setIsLoading(true)
        fetch(config.rest.uri + "/V1/customers/password", {
            method: RESTMethods.PUT,
            headers: {
                "authorization": token ? `Bearer ${token}` : "",
                "content-type": "application/json",
                "accept": "application/json",
            },
            body: JSON.stringify({
                email: email,
                template: "email_reset",
                websiteId: 1,
            })
        })
            .then(res => res.json())
            .then(() => {
                setIsLoading(false)
                setMessage(`If there is an account associated with ${email} you will receive an email with a link to reset your password.`)
            })
    }

    return (
        <ForgotPassWrap>
            <ForgotPassTopControls>
                <Button onClick={props.toggleForgotPass}>
                    {controlIcons.returnChevronSmall}
                </Button>
            </ForgotPassTopControls>
            <ForgotPassLogo>
                <img src={require("../assets/images/logo@2x.png")} alt="company logo" className={"loginLogo"}/>
            </ForgotPassLogo>
            <h1>
                Forgot Password?
            </h1>
            <p>
                Enter your email address and we will send you instructions to reset your password.
            </p>
            {message && <Alert
                message={message}
                type="success"
                showIcon
                style={{margin: "0 50px", marginBottom: "20px"}}
            />}
            <InputWrap>
                <h2>Email</h2>
                <Input type={"email"} maxLength={50} onChange={(element) => setEmail(element.target.value)} value={email} />
                <Button onClick={resetPassword} loading={isLoading}>Send</Button>
            </InputWrap>
        </ForgotPassWrap>
    )
}

const ForgotPassWrap = styled.div`
            }
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFF;
  h1 {
    font-family: Georgia;
    font-size: 26px;
    color: #222222;
    text-align: center;
    margin-top: 24px;
  }
  p {
    font-family: Noto Sans;
    font-size: 14px;
    color: #606060;
    margin: 0 49px 24px 49px;
  }
`

const ForgotPassTopControls = styled.div`
  padding: 5px 0 0 4px;
  button {
    width: 48px;
    height: 48px;
    border: none;
    background-color: transparent !important;
  }
`

const ForgotPassLogo = styled.div`
  display: flex;
  justify-content: center;
  padding: 38px 0 0 0;
`

const InputWrap = styled.div`
  padding: 0 49px;
  h2 {
    font-family: Georgia;
    font-size: 15px;
    color: #353C43;
  }
  button {
    width: 100%;
    height: 40px;
    background-color:  #11365C;
    font-family: Noto Sans;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    :hover {
      background-color:  #11365C;
    }
  }
`

