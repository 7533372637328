export const madcdn = (url: string, width: number, height: number): string => {
    let cdnUrl
    if (url !== null && url.startsWith('/')) {
        cdnUrl = `https://images.madcdn.dev/jock${url}`
    } else if (url !== null) {
        cdnUrl = url.replace('jock-api-stg.mad.app/', 'images.madcdn.dev/jock/')
    }
    cdnUrl = `${cdnUrl}?w=${width}&h=${height}&dpr=${window.devicePixelRatio}`
    return cdnUrl
}
