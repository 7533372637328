import ReactGA from 'react-ga'
import {CommonCart_items} from "../graphql/requests/types/CommonCart"
import {GetCheckoutCart_cart_items} from "../graphql/requests/types/GetCheckoutCart"
import config from "../config"

interface ProductDetailProps {
    id: string
    name: string
    range: string
}

interface CartProps {
    id: string
    name: string
    range?: string
    price?: string
    quantity?: string
}

interface TransactionDetails {
    id: string
    affiliation: string
    revenue: string
    tax: string
    shipping: string
    coupon: string|null
}

const trackingId = config.googleAnalytics.trackingID

ReactGA.initialize(trackingId)
ReactGA.ga('require', 'ec')

export const onGaProductDetailView = (options: ProductDetailProps) => {
        // The product being viewed.
        ReactGA.ga('ec:addProduct', options)
        ReactGA.ga('ec:setAction', 'detail') // Detail action.
        ReactGA.ga('send', 'pageview') // Send the product data with initial pageview.
}

export const onGaAddToCart = (options: CartProps, isCartItems?: boolean|undefined) => {
    // Called when a product is added to a shopping cart.
    ReactGA.ga('ec:addProduct', options)
    if (!isCartItems) {
        ReactGA.ga('ec:setAction', 'add')
        ReactGA.ga('send', 'event', 'UX', 'click', 'add to cart')     // Send data using an event.
    }
}

export const onGaRemoveFromCart = (options: CartProps) => {
    // Called when a product is removed from a shopping cart.
    ReactGA.ga('ec:addProduct', options)
    ReactGA.ga('ec:setAction', 'remove')
    ReactGA.ga('send', 'event', 'UX', 'click', 'remove from cart') // Send data using an event.
}

export const onGaCheckout = (stepNumber: number, checkoutOption?: string|undefined|null, cartItems?: CommonCart_items[]|GetCheckoutCart_cart_items[]|null, transaction?: TransactionDetails) => {
    ReactGA.ga('ec:setAction', 'checkout_option', {
        'step': stepNumber,
        'option': checkoutOption
    })

    ReactGA.ga('send', 'event', 'Checkout', 'Option', {
        hitCallback: function () {
            if (stepNumber === 3) {
                onGaPurchase(cartItems, transaction)
            }
        }
    })
}

const onGaPurchase = (cartItems?: CommonCart_items[]|GetCheckoutCart_cart_items[]|null, transaction?: TransactionDetails) => {
    // Called on checkout view
    if (cartItems) {
        // @ts-ignore
        cartItems.map((item) => onGaAddToCart({
            name: item.product.name,
            price: item.prices.row_total_including_tax.value.toString(),
            quantity: item.quantity.toString(),
            range: item.product.jock_info.range,
            id: item.product.sku,
        }, true))
    }
    // Transaction level information is provided via an actionFieldObject.
    ReactGA.ga('ec:setAction', 'purchase', transaction)
    ReactGA.ga('send', 'pageview') // Send the product data with initial pageview.
}
